type Props = {
  onClick: () => void;
};

const CookieBanner = ({ onClick }: Props) => {
  return (
    <div className="flex fixed bottom-0 z-50 flex-row-reverse items-center px-4 py-2 pt-0 min-w-full text-sm bg-white border md:pt-3 md:py-3 md:block lg:bottom-2 lg:right-2 md:min-w-0 md:max-w-sm md:rounded-md md:right-10 md:bottom-8">
      <div className="flex justify-between items-center">
        <div className="hidden gap-3 items-center md:flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
            <path
              fill="#000"
              d="M0 9a8 8 0 019.874-7.78.5.5 0 01.232.846 2.001 2.001 0 00.955 3.386A.562.562 0 0111.5 6a2 2 0 003.503 1.32.5.5 0 01.868.24 7.998 7.998 0 01-10.422 9.013A8 8 0 010 9zm5-2a1 1 0 10-2 0 1 1 0 002 0zm3 3a1 1 0 100-2 1 1 0 000 2zm3 3a1 1 0 10-2 0 1 1 0 002 0zm-6 0a1 1 0 100-2 1 1 0 000 2z"
            ></path>
          </svg>
          <h4 className="font-bold text-black">
            Cookies
          </h4>
        </div>
        <button
          name="close-cookie-popup"
          id="close-cookie-popup"
          title="close-cookie-popup"
          onClick={onClick}
          className="p-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
            <path
              fill="#000"
              d="M11 .5C5.202.5.5 5.202.5 11S5.202 21.5 11 21.5 21.5 16.798 21.5 11 16.798.5 11 .5zm3.877 14.49l-1.547-.008L11 12.205 8.673 14.98l-1.55.007a.187.187 0 01-.187-.188c0-.044.016-.086.044-.122l3.05-3.633-3.05-3.63a.188.188 0 01.143-.31l1.55.008L11 9.889l2.327-2.775 1.547-.007c.103 0 .188.082.188.188a.195.195 0 01-.045.121l-3.044 3.63 3.046 3.634a.188.188 0 01-.143.31z"
            ></path>
          </svg>
        </button>
      </div>
      <p className="mt-2 text-gray-500 md:mt-4">
        By using this website, you automatically accept our cookie policy.
      </p>
    </div>
  );
};

export default CookieBanner;
