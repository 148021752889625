import { useEffect } from "react";
import Cookies from "js-cookie";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { useAd } from "@/hooks/useAd";
import getURL from "@/utils/getUrl";
import { startUserSession } from "@/utils/tvlUserApis";
import { returnRouteName } from "@/utils/returnRouteName";
import { getRoute } from "@/utils/getRoute";
import { useRouter } from "next/router";
import { fireEvent } from "@/utils/fireEvent";
import { useUserId } from "@/hooks/useUserId";

export function PopupAd() {
	const router = useRouter();
	const countryCode = Cookies.get("region") ?? "us";
	const kayakBaseUrl = getURL(countryCode).replace(/^(https?:\/\/)?/, "https://");
	const { open, closeAd, adData, onSkip } = useAd();
	const { userId } = useUserId();
	const searchParams = useSearchParams();
	const ad = adData?.inlineItems[0];

	useEffect(() => {
		if (open && ad) fetch(new URL(ad.impressionUrl, kayakBaseUrl)).catch(() => { });
	}, [open, ad]);

	useEffect(() => {
		if (open) document.body.classList.add("!overflow-hidden");
		else document.body.classList.remove("!overflow-hidden");
	}, [open]);

	const handleAdClick = () => {
		startUserSession({
			tag: "ad_click",
			user_id: userId,
			route: (returnRouteName(getRoute(router.query)) === "ads" ? "google" : returnRouteName(getRoute(router.query))) as VisitRoute,
			country_code: Cookies.get("region")?.toUpperCase(),
			vertical: "hotel",
			gclid: searchParams.get("gclid") || undefined,
			msclkid: searchParams.get("msclkid") || undefined,
			fbclid: searchParams.get("fbclid") || undefined,
		});
		fireEvent("ad_click_web", {
			type: "pop_up_ad",
			location: Cookies.get("pickupLocationName"),
			arrival_location: Cookies.get("dropOffLocationName")
		});
		window.open(new URL(ad!.deepLink, kayakBaseUrl), "_blank");
		closeAd();
	};

	return (open && ad) ? (
		<div className="flex fixed inset-0 z-40 flex-col justify-center items-center w-screen h-screen bg-black bg-opacity-60" onClick={e => { if (e.currentTarget === e.target) closeAd(); }}>
			<div className="flex flex-col gap-1 justify-center items-center max-w-sm w-fit h-fit">
				<div className="rounded-[1.25rem] w-full h-14 text-white text-center font-extrabold flex text-lg justify-center items-center ad-offer-anim-gradient">
					{"Trending Offer"}
				</div>
				<div className="flex relative flex-col justify-between px-5 py-6 w-full bg-white rounded-[1.25rem] h-min-[400px] gap-3">
					<div className="object-cover absolute right-5 top-6 w-6 ad-marker"></div>
					<img className="p-3 rounded-lg border" src={new URL(ad.logoUrl, kayakBaseUrl).href} alt="Logos" width={145} height={25} />

					<div className="flex flex-col gap-3">
						<div className="text-3xl font-extrabold text-[#182C47]">
							{ad.headline}
						</div>
						<div className="text-lg font-medium text-[#182C47]">
							{ad.description}
						</div>
					</div>

					<div className="flex flex-col gap-2">
						<button id="booking-button" className="h-16 font-semibold text-white rounded-xl transition bg-gradient-to-r from-[#FFAF2E] to-[#F57F2E] hover:scale-105" onClick={handleAdClick}>
							{ad.bookingButtonText}
						</button>
						<button id="skip-ad-button"
							className="flex gap-2 justify-center items-center h-14 font-semibold rounded-xl transition hover:scale-95"
							onClick={(e) => { closeAd(); if (onSkip) onSkip(e); }}
						>
							Continue to Search
							<Image src={"/right-arrow.svg"} alt="continue" width={18} height={18} />
						</button>
					</div>
				</div>
			</div>
		</div>
	) : null;
}